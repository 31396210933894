import * as React from "react"

import Layout from "../components/Layout"
import SEO from "../components/seo"

import * as AboutUs from "../components/Aboutus"

const SobreNosPage = ({ transitionStatus, entry }) => (
  <Layout transitionStatus={transitionStatus} entry={entry}>
    <SEO title="Sobre nós" />
    <AboutUs.Apresentation transitionStatus={transitionStatus} />
    <AboutUs.TextSection transitionStatus={transitionStatus} title="Nosso time">
      <p>
        Somos uma empresa familiar. Trabalhando juntos, cada um com a sua
        especialidade.
      </p>
    </AboutUs.TextSection>
    <AboutUs.Temates transitionStatus={transitionStatus} />
    <AboutUs.TextSection
      transitionStatus={transitionStatus}
      title="Nossos valores"
    >
      <p>
        Prestar um ótimo atendimento ao cliente desde o primeiro contato até a
        finalização do serviço contratado.
      </p>
    </AboutUs.TextSection>
  </Layout>
)

export default SobreNosPage
