import React, { useRef, useEffect } from "react"
import styled from "styled-components"
import media from "styled-media-query"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

export const TextSection = ({ title, children, transitionStatus }) => {
  gsap.registerPlugin(ScrollTrigger)

  const containerRef = useRef(null)
  const titleRef = useRef(null)
  const textRef = useRef(null)

  useEffect(() => {
    const tl = gsap.timeline()
    tl.from(titleRef.current, {
      scrollTrigger: containerRef.current,
      textIndent: "-100%",
      duration: 0.5,
      delay: 1,
      onComplete: () => {
        titleRef.current?.classList.add("animated")
      },
    }).from(textRef.current, {
      scrollTrigger: containerRef.current,
      y: 100,
      opacity: 0,
      duration: 0.7,
    })
  }, [])

  useEffect(() => {
    if (transitionStatus === "exiting") {
      const tl = gsap.timeline()
      tl.to(titleRef.current, {
        scrollTrigger: containerRef.current,
        y: -100,
        opacity: 0,
      }).to(
        textRef.current,
        {
          scrollTrigger: containerRef.current,
          y: -100,
          opacity: 0,
        },
        "-=0.5"
      )
    }
  }, [transitionStatus])

  return (
    <Container ref={containerRef}>
      <Wrapper>
        <SectionTitle ref={titleRef}>{title}</SectionTitle>
        <DataContainer ref={textRef}>{children}</DataContainer>
      </Wrapper>
    </Container>
  )
}

const Container = styled.section`
  width: 100%;
  margin-bottom: 50px;
`

const Wrapper = styled.div`
  width: 90%;
  max-width: 1024px;
  margin: 0 auto;
`

const SectionTitle = styled.h2`
  position: relative;
  margin-top: 50px;
  margin-bottom: 20px;
  margin-left: 10px;
  text-transform: uppercase;

  overflow: hidden;

  &.animated {
    overflow: visible;
    &::before {
      opacity: 1;
    }
  }

  &::before {
    content: "";
    width: 5px;
    height: 100%;
    position: absolute;
    top: 0;
    left: -10px;
    background: var(--primary-red-color);
    opacity: 0;
    transition: all 500ms ease-in-out;
  }

  span {
    font-weight: 900;
  }
`

const DataContainer = styled.div`
  width: 50%;
  font-size: clamp(0.1rem, 5vw, 1.3rem);

  ${media.lessThan("medium")`
    width: 100%;
  `}
`
