import React, { useRef, useEffect } from "react"
import styled from "styled-components"
import media from "styled-media-query"
import { StaticImage } from "gatsby-plugin-image"
import gsap from "gsap"

export const Apresentation = ({ transitionStatus }) => {
  const textRef = useRef(null)
  const imageRef = useRef(null)

  useEffect(() => {
    const tl = gsap.timeline()

    tl.from(textRef.current.children[0], {
      y: 100,
      opacity: 0,
    })
      .from(
        textRef.current.children[1],
        {
          y: 100,
          opacity: 0,
          delay: 0.2,
        },
        "<"
      )
      .from(imageRef.current, { opacity: 0 })
  }, [])

  useEffect(() => {
    if (transitionStatus === "exiting") {
      const tl = gsap.timeline()

      tl.to(textRef.current.children[0], {
        y: -100,
        opacity: 0,
      })
        .to(
          textRef.current.children[1],
          {
            y: -100,
            opacity: 0,
            delay: 0.1,
          },
          "<"
        )
        .to(imageRef.current, { opacity: 0 })
    }
  }, [transitionStatus])

  return (
    <Container>
      <Wrapper>
        <Description>
          <DescriptionTextArea ref={textRef}>
            <h2>Construindo seu sonho</h2>
            <p>
              A Cross é uma das empresas de projeto, arquitetura e construção
              mais antigas de Peruíbe, atuando desde 1979. Já ajudamos a
              construir boa parte da história da cidade e temos muito orgulho de
              cativar até hoje a fidelidade dos clientes, de geração em geração.
            </p>
          </DescriptionTextArea>
          <DescriptionImage ref={imageRef}>
            <StaticImage src="../../images/dark-logo.png" />
          </DescriptionImage>
        </Description>
      </Wrapper>
    </Container>
  )
}

const Container = styled.section`
  width: 100%;
  margin-top: 150px;

  ${media.lessThan("medium")`
    margin-top: 100px;
  `}
`

const Wrapper = styled.div`
  width: 90%;
  max-width: 1024px;
  margin: 0 auto;
`

const Description = styled.div`
  display: flex;
  justify-content: space-between;

  ${media.lessThan("medium")`
    flex-direction: column-reverse;
    justify-content: flex-start;
    align-items: center;
  `}
`

const DescriptionTextArea = styled.div`
  width: 50%;
  padding-right: 50px;

  h2 {
    font-size: clamp(1rem, 7vw, 1.8rem);
    margin-bottom: 10px;
  }

  p {
    font-size: clamp(0.1rem, 5vw, 1.3rem);
  }

  ${media.lessThan("medium")`
    width: 100%;
    margin: 50px 0;
  `}
`

const DescriptionImage = styled.div`
  width: 40%;

  img {
    width: 100%;
  }

  ${media.lessThan("medium")`
    width: 100%;
    max-width: 200px;
  `}
`
