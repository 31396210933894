import React, { useRef, useEffect } from "react"
import styled from "styled-components"
import media from "styled-media-query"
import gsap from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"
import { StaticImage } from "gatsby-plugin-image"

export const Temates = ({ transitionStatus }) => {
  gsap.registerPlugin(ScrollTrigger)

  const tematesRef = useRef([])
  tematesRef.current = []

  useEffect(() => {
    tematesRef.current.forEach(temate => {
      gsap.from(temate, {
        scrollTrigger: temate,
        y: 100,
        opacity: 0,
        delay: 0.5,
        duration: 0.7,
        onComplete: () => {
          temate.children[0].classList.add("animated")
        },
      })
      return
    })
  }, [])

  useEffect(() => {
    if (transitionStatus === "exiting") {
      gsap.to(tematesRef.current, {
        opacity: 0,
        duration: 0.3,
      })
    }
  }, [transitionStatus])

  const addToRef = el => {
    if (el && !tematesRef.current.includes(el)) {
      tematesRef.current.push(el)
    }
  }

  return (
    <Container>
      <Wrapper>
        <Temate ref={addToRef}>
          <TemateImage>
            <StaticImage src="../../images/profiles/2.png" />
          </TemateImage>
          <TemateName>Carlos Alberto Tamagnini</TemateName>
          <TemateCharge>Sócio fundador</TemateCharge>
        </Temate>
        <Temate ref={addToRef}>
          <TemateImage>
            <StaticImage src="../../images/profiles/3.png" />
          </TemateImage>
          <TemateName>Ivete Beierle Tamagnini</TemateName>
          <TemateCharge>Sócia e braço direito da empresa</TemateCharge>
        </Temate>
        <Temate ref={addToRef}>
          <TemateImage>
            <StaticImage src="../../images/profiles/1.png" />
          </TemateImage>
          <TemateName>Mariani Beierle Tamagnini</TemateName>
          <TemateCharge>Sócia e arquiteta responsável</TemateCharge>
        </Temate>
      </Wrapper>
    </Container>
  )
}

const Container = styled.section`
  width: 100%;
  margin-top: 30px;
`

const Wrapper = styled.div`
  width: 90%;
  max-width: 1024px;
  margin: 0 auto;

  display: grid;
  gap: 40px;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-auto-rows: 1fr;

  ${media.lessThan("medium")`
    gap: 20px;
    grid-template-columns: 1fr 1fr;
  `}
`

const Temate = styled.div`
  text-align: center;
`

const TemateImage = styled.div`
  position: relative;
  margin-bottom: 20px;

  &::before {
    content: "";
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background: red;
    transition: all 300ms ease-in-out;
  }

  &.animated::before {
    top: 5px;
    left: 10px;
  }

  img {
    width: 100%;
    filter: grayscale(100%);
  }
`

const TemateName = styled.h3`
  font-weight: 900;
`

const TemateCharge = styled.p``
